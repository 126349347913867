<script lang="ts">
	import { getProductPath } from "../../../../core/schema/paths/getProductPath.js";
	import Link from "../Link.svelte";
	import { getGlobalSettings } from "../../getGlobalSettings.js";
	import type { ProductForCustomer } from "../../../../core/schema/Product.js";
	import Button from "../Button.svelte";
	import { isProductForCustomerConfigurable } from "../../../../core/schema/utils/isProductForCustomerConfigurable.js";
	import cake from "../../../../core/assets/icons/cake.svg?raw";
	import check from "../../../../core/assets/icons/check.svg?raw";
	import ProductSizeInputs from "./ProductSizeInputs.svelte";
	import type { ProductSize } from "../../../../core/schema/ProductSize.js";
	import { doesProductForCustomerHaveMultipleSizes } from "../../../../core/schema/utils/doesProductForCustomerHaveMultipleSizes.js";
	import ProductCardImage from "./ProductCardImage.svelte";
	import { formatPrice } from "../../../../core/utils/formatPrice.js";
	import { nbspify } from "../../../../core/utils/nbspify.js";
	import ProductPriorityLabel from "./ProductPriorityLabel.svelte";
	import { reportAddedProductToCart } from "../../UniversalAnalytics.js";
	import { emptyOptions } from "../../../../core/schema/utils/emptyOptions.js";
	import NumberInput from "../Form/NumberInput.svelte";
	import Form from "../Form/Form.svelte";
	import { animateCartCount } from "../../utils/animateCartCount.js";
	import type { ProductAmountPerCategory } from "../../../../core/schema/ProductAmountPerCategory.js";
	import { getFreeSlotsForProduct } from "../../../../core/schema/utils/getFreeSlotsForProduct.js";
	import { getCartStore } from "../../contexts/cartStoreContextKey.js";
	import { shouldShowProductForCustomerSize } from "../../../../core/schema/utils/shouldShowProductForCustomerSize.js";
	import { isProductCurrentlyPurchasable } from "../../../../core/schema/utils/isProductPurchasable.js";
	import { productOptionKeyMap, type ProductOption } from "../../../../core/schema/ProductOption.js";
	import { minimalOptions } from "../../../../core/schema/productOptionPrices.js";
	import { getPriceOfOrderProduct } from "../../../../core/schema/utils/getPriceOfOrderProduct.js";
	import { transformProductToOrderProduct } from "../../../../core/schema/utils/transformProductToOrderProduct.js";
	import { isSimpleToggleProductOption } from "../Configurator/isSimpleToggleProductOption.js";
	import { CustomerType } from "../../../../core/schema/CustomerType.js";
	import { isProductOptionUsed } from "../../../../core/schema/utils/isProductOptionUsed.js";
	import { requiredProductOptions } from "../../../../core/schema/requiredProductOptions.js";
	import { getDefaultSizeForProductForCustomerWithExpress } from "../../../../core/schema/utils/getDefaultSizeForProductForCustomerWithExpress.js";
	import ExpressProductWarningModal from "./ExpressProductWarningModal.svelte";
	import type { PriorityLabel } from "../../../../core/schema/PriorityLabel.js";

	export let asCrossSale = false;
	export let isWarehouseProduct = false;
	export let product: ProductForCustomer;
	export let index = 0;
	export let maxProductsPerCategory: ProductAmountPerCategory;
	export let enabledOption: ProductOption | undefined = undefined;

	let form: HTMLFormElement | null;

	const cartStore = getCartStore();
	const { TENANT_LOCALE, WEB_URL } = getGlobalSettings();

	$: priorityLabel = isWarehouseProduct ? ("express" as const) : product.priorityLabel;

	let showExpressWarningModal = false;
	let selectedSize = getDefaultSizeForProductForCustomerWithExpress(product, isWarehouseProduct);

	$: isInCart = $cartStore.value.order.products.some(
		({ product: { id }, size }) =>
			id === product.id && (doesProductForCustomerHaveMultipleSizes(product) ? size === selectedSize : true),
	);

	let options = structuredClone(emptyOptions);
	$: customizable = isProductForCustomerConfigurable(product, false, selectedSize);
	$: {
		if (customizable && enabledOption !== undefined) {
			if (isSimpleToggleProductOption(enabledOption)) {
				options = {
					...options,
					[productOptionKeyMap[enabledOption]]: true,
				};
			}
		}
	}

	$: priceWithEnabledOption = getPriceOfOrderProduct(
		transformProductToOrderProduct(
			crypto.randomUUID(),
			product,
			1,
			selectedSize,
			enabledOption !== undefined && !isSimpleToggleProductOption(enabledOption)
				? { ...options, [productOptionKeyMap[enabledOption]]: minimalOptions[productOptionKeyMap[enabledOption]] }
				: options,
		),
	);
	$: maximumOrderQuantity = getFreeSlotsForProduct(product, maxProductsPerCategory, $cartStore.value.order.products);
	$: canAddToCart =
		maximumOrderQuantity >= product.minimumOrderQuantity &&
		isProductCurrentlyPurchasable(product, CustomerType.B2c, isWarehouseProduct) &&
		(!product.soldOut || $cartStore.value.properties.canAddSoldOutExpressProduct);

	$: link = `/${getProductPath(TENANT_LOCALE, product)}`;

	function withSizeQueryParam(link: string, size: ProductSize): string {
		const url = new URL(`${WEB_URL}${link}`);
		url.searchParams.set("size", size);
		return `${url.pathname}${url.search}`;
	}

	function withConfigQueryParam(link: string): string {
		const url = new URL(`${WEB_URL}${link}`);
		url.searchParams.set("config", "");
		return `${url.pathname}${url.search}`;
	}

	function withExpressQueryParam(link: string): string {
		const url = new URL(`${WEB_URL}${link}`);
		url.searchParams.set("express", "");
		return `${url.pathname}${url.search}`;
	}

	function withEnabledOptionQueryParam(link: string, option: ProductOption | undefined): string {
		const url = new URL(`${WEB_URL}${link}`);
		if (option !== undefined) {
			url.searchParams.set("option", option);
		}
		return `${url.pathname}${url.search}`;
	}

	function getLinkToProduct(
		link: string,
		product: ProductForCustomer,
		selectedSize: ProductSize,
		isWarehouseProduct: boolean,
		enabledOption: ProductOption | undefined,
	): string {
		let linkToProduct = doesProductForCustomerHaveMultipleSizes(product)
			? withSizeQueryParam(link, selectedSize)
			: link;
		linkToProduct = withEnabledOptionQueryParam(linkToProduct, enabledOption);
		if (isWarehouseProduct) {
			linkToProduct = withExpressQueryParam(linkToProduct);
		}

		return linkToProduct;
	}

	$: linkToProduct = getLinkToProduct(link, product, selectedSize, isWarehouseProduct, enabledOption);

	async function putItemToCart(formData: FormData): Promise<void> {
		for (const enabledOption of product.enabledOptions) {
			if (requiredProductOptions.includes(enabledOption)) {
				if (!isProductOptionUsed(enabledOption, options[productOptionKeyMap[enabledOption]])) {
					let targetUrl = withConfigQueryParam(withEnabledOptionQueryParam(link, enabledOption));
					if (isWarehouseProduct) {
						targetUrl = withExpressQueryParam(targetUrl);
					}
					window.location.href = targetUrl;
					return;
				}
			}
		}

		const quantity = Number(formData.get("quantity"));

		const response = await cartStore.addProduct(
			product,
			quantity,
			product.minimumOrderQuantity,
			selectedSize,
			isWarehouseProduct,
			asCrossSale,
			customizable ? options : undefined,
		);

		reportAddedProductToCart(response.item, TENANT_LOCALE);
		animateCartCount();
	}

	async function submit(forceSubmit: boolean): Promise<void> {
		if (!form) {
			throw new Error("Form not initialized");
		}

		if ($cartStore.value.order.isExpress && !isWarehouseProduct && !forceSubmit) {
			showExpressWarningModal = true;
		} else {
			await putItemToCart(new FormData(form));
			showExpressWarningModal = false;
		}
	}
</script>

<ExpressProductWarningModal
	{product}
	bind:showExpressWarningModal
	addItem={async () => {
		await submit(true);
	}}
/>

<div
	class="flex h-fit w-full flex-col rounded-[1.375rem] border border-transparent transition-all duration-300 min-[430px]:w-[initial] min-[430px]:basis-[calc(50%-0.5rem)] sm:basis-[calc(50%-0.25rem)] sm:p-4 sm:hover:border-gray-300 md:basis-[calc(50%-0.75rem)] lg:basis-[calc((100%-(2*.75rem))/3)] xl:basis-[calc((100%-(2*1.5rem))/3)] xl:p-6"
>
	<a
		href={linkToProduct}
		class="group relative block aspect-square h-auto w-full overflow-hidden rounded-lg min-[430px]:min-h-[11.9375rem] min-[430px]:min-w-[11.9375rem]"
	>
		{#if priorityLabel !== undefined}
			<ProductPriorityLabel class="text-2xs absolute left-2 top-2 z-10 font-semibold" label={priorityLabel} />
		{/if}
		<ProductCardImage
			{selectedSize}
			loading={index < 5 ? "eager" : "lazy"}
			{product}
			size={400}
			logo={{
				class:
					"min-w-[6rem] max-w-[28%] md:w-32 min-[430px]:min-w-[initial] min-[430px]:max-w-[40%] md:max-w-[initial] xl:w-[8.5rem]",
				size: 136,
			}}
		/>
	</a>
	<Form
		bind:form
		on:submit={async () => {
			await submit(false);
		}}
		class="mt-4 grid min-h-[9.25rem] grid-rows-[auto,auto,auto] min-[430px]:min-h-[11.5rem] md:min-h-[9.25rem] lg:min-h-[12rem] xl:min-h-[9.25rem]"
	>
		<a
			class="text-md md:text-md line-clamp-2 h-14 font-semibold leading-7 min-[430px]:text-base min-[430px]:leading-[1.3] md:leading-[1.5]"
			href={linkToProduct}
		>
			{product.name[TENANT_LOCALE]}
		</a>
		<div
			class="mb-2 flex justify-between gap-0.5 min-[430px]:mb-0 min-[430px]:flex-col md:mb-2 md:flex-row lg:mb-0 lg:flex-col xl:mb-2 xl:flex-row"
		>
			<div class="flex flex-wrap items-center gap-x-3 md:gap-x-2 lg:gap-x-3">
				<!-- INFO: Minimum order quantity wins over multiple sizes. -->
				{#if product.minimumOrderQuantity > 1}
					<div
						class="flex flex-wrap items-center gap-2"
						class:opacity-0={isInCart}
						class:pointer-events-none={isInCart}
					>
						<span class="text-xs">Počet kusů</span>
						<NumberInput
							id="quantity"
							required
							min={product.minimumOrderQuantity}
							max={maximumOrderQuantity}
							class="h-9 w-32 p-1.5"
							value={product.minimumOrderQuantity}
						/>
					</div>
				{:else if shouldShowProductForCustomerSize(product)}
					<span class="min-[430px]:text-2xs pt-[0.3125rem] text-xs font-normal leading-5 md:text-xs">Velikost</span>
					<ProductSizeInputs {product} isSizeAddedToCart={isInCart} bind:selectedSize isExpress={isWarehouseProduct} />
				{/if}
			</div>
			<span
				class="text-md flex items-center leading-7 min-[430px]:mb-1 min-[430px]:mt-3 md:my-0 lg:mb-2 lg:mt-3 xl:my-0 {customizable ||
				product.minimumOrderQuantity > 1
					? 'justify-between'
					: 'justify-end'}"
			>
				{#if customizable && canAddToCart}
					<Link
						class="text-2xs hidden !py-[.175rem] font-semibold min-[430px]:block md:hidden lg:block lg:text-xs xl:hidden"
						href={withConfigQueryParam(linkToProduct)}
						asButton
						variant="secondary">Upravit</Link
					>
				{/if}
				<span class="font-semibold">
					{#if enabledOption !== undefined && !isSimpleToggleProductOption(enabledOption)}
						Od
					{/if}
					{nbspify(formatPrice(priceWithEnabledOption.withTax, TENANT_LOCALE, false))}
				</span>
			</span>
		</div>
		<div class="flex items-end gap-4 {customizable && canAddToCart ? 'justify-between' : 'justify-end'}">
			{#if customizable && canAddToCart}
				<Link
					class="min-[430px]:hidden md:block lg:hidden xl:block"
					href={withConfigQueryParam(linkToProduct)}
					asButton
					variant="secondary"
				>
					Upravit
				</Link>
			{/if}
			<Button
				type="submit"
				variant={canAddToCart ? "primary" : "secondary"}
				class="whitespace-nowrap text-base font-semibold leading-5 min-[430px]:w-full min-[430px]:justify-center min-[430px]:text-xs md:w-fit md:justify-start md:text-base lg:w-full lg:justify-center xl:w-fit xl:justify-start {isInCart
					? '!bg-tertiary !border-tertiary hover:!bg-tertiary focus:!bg-tertiary active:!bg-tertiary hover:!border-tertiary focus:!border-tertiary active:!border-tertiary pointer-events-none !text-white hover:!text-white focus:!text-white active:!text-white'
					: ''}"
				iconClass="w-[0.875rem]"
				icon={isInCart ? check : cake}
				disabled={!canAddToCart}
			>
				<div class="together">
					{#if !canAddToCart}
						Aktuálně nedostupné
					{:else}
						<div class:opacity-0={isInCart}>Do krabice</div>
						<div class:opacity-0={!isInCart}>V krabici</div>
					{/if}
				</div>
			</Button>
		</div>
	</Form>
</div>

<style lang="postcss">
	.together {
		display: grid;
		grid-template-areas: "single";

		& > * {
			grid-area: single;
		}
	}
</style>
